@import url("https://use.typekit.net/fvk4nfp.css");

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Black.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Bold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler.woff2") format("woff2"),
    url("./Assets/Fonts/Butler.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$color1: #4389C9;
$color1opacity30: #4389C930;
$color1opacity42: #4389C942;
$color1opacity60: #4389C960;

$color2: #1EA74A;

$primaryfont: "lato";

body { background: #152036; font-family: "lato", sans-serif; color: #1f2a44; }

.desktop { display: block; }
.mobile { display: none; }

header {
  & { background: #FFFFFF; height: 80px; display: flex; align-items: center; justify-content: center; width: 100%; }
  .logo { 
    & { position: absolute; left: 100px; cursor: default; }
    img { max-height: 80px; max-width: 129px; }
  }
  .assessment-title { 
    & { flex: 1 1 auto; text-align: center; }
    img { height: auto; max-width: 277px; margin-top: 5px; } 
  }
  .build-by {
    & { position: absolute; right: 100px; }
    img { width: 50px; max-height: 64px; }
  }
}

main {
  .container { margin: 80px auto; max-width: 1110px; border-radius: 10px; }
  .loading { display: flex; justify-content: center; align-items: center; }
  
  .start { 
    & { height: 580px; background: #E7E9EB; display: flex; }
    .video { 
      & { border-top-left-radius: 10px; border-bottom-left-radius: 10px; position: relative; width: 50%; background: #333; height: 100%; display: flex; justify-content: center; object-fit: cover; overflow: hidden; }
      video { margin: 0px 0 0 -50px; object-fit: cover; }
      .thumbnail { position: absolute; height: 100%; overflow: hidden; }
      .playbtn { position: absolute; top: 50%; margin-top: -72px; left: 50%; margin-left: -72px; cursor: pointer; }
    }
    .intro {
      & { width: 40%; padding: 0 4%; display: flex; align-items: left; flex-direction: column; justify-content: center; }
      h1 { margin: 0; margin-bottom: 28px; font-family: "lato", sans-serif; font-weight: 700; font-size: 38px; color: #343333; }
      h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-style: normal; color: #343333; font-size: 24px; line-height: 35px; }
      .completion { 
        & { display: flex; align-items: center; margin-top: 48px; color: #343333; font-weight: 700; font-size: 18px; }
        .cta { display: block; background: $color1; border-radius: 25px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 10px 0 14px; margin-left: 0; }
        .cta:hover { background: #1ea74a; transition: 0.3s; color: #fff; }
        img { width: 25px; height: 25px; margin: 0 4px 0 45px; }
      }
    }
  }

  .assessment {
    & { min-height: 580px; background: #fff; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }

      .progress-bar {
        & { display: flex; justify-content: space-between; width: 100%; }
        .bar-section { 
          .bar { height: 6px; margin: 0 5px 10px; }
          .section-title { width: 100%; text-align: center; font-family: 'lato', sans-serif; font-weight: 400; color: #343333; font-size: 21px; }
        }

        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
      }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: #202945; font-size: 18px; }

      .question { min-height: 150px; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: #343333; font-size: 24px; }

      .radio-container {
        & { width: 86%; margin: 0 auto; }
        
        .slider-labels {
          & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: rgb(62, 133, 199); }
        }

        .MuiRadio-root { margin-left: -20px; }
        
      }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: rgb(62, 133, 199); }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; width: calc(100% + 10px); margin-left: -5px; top: -14px; position: absolute; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background: $color1; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -1px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; position: absolute; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 90px auto 30px; }
        button { color: #343333; font-weight: 600; font-size: 14px; font-family: "lato", sans-serif; }
      }
    }
  }

  .assessment-additional {
    & { min-height: 580px; background: #fff; border-radius: 10px; color: #182036; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }
      h1 {  color: #182036; font-size: 18px; text-align: center; }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: "lato", sans-serif; font-weight: 400; color: $color1; font-size: 18px; }

      .question { min-height: 150px; text-align: center; font-family: "lato", sans-serif; font-weight: 400; color: #182036; font-size: 24px; }
      .textarea { min-height: 80px; }
      .textarea-field { max-width: 540px; width: 80%; margin: 0 auto; margin-bottom: -37px; }
      .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 21px; border: 1px solid #979797; padding: 14px 18px; border-radius: 5px; }
      .form-details-text { background-color: #fff; padding: 0 9px 22px; height: 90px; }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #182036; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: #182036; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
          .active { font-weight: 700; color: rgb(62, 133, 199); }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background: $color1; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -2px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 80px auto 30px; padding-bottom: 30px; }
        button { color: #182036; font-weight: 600; font-size: 14px; font-family: "lato", sans-serif; }
        .nextbtn { background: #3E85C7; color: #fff; padding: 14px 26px; border-radius: 25px; }
        .nextbtn.Mui-disabled { background: #C1C1C1; }
      }
    }
  }

  .details {
    & { min-height: 630px; max-width: 904px; width: 100%; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "lato", sans-serif; font-size: 36px; color: #1f2a44; padding: 32px 0 0; }
    p { text-align: center; font-size: 21px; font-weight: 400; line-height: 1.4em; }

    .form {
      & { display: flex; justify-content: space-between; flex-wrap: wrap; margin: 58px auto; width: 60%; }
      .form-detail { 
        & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 50px; font-weight: 700; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 600; }
        p span { color: #D3202B; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .MuiSelect-select .first-menu { color: #fff; }
        .form-details-text { background-color: #F4F4F4; padding: 12px 15px; max-width: 90%; }
        .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset !important;
        }
      }
    }

    .cta-btn {
      & { margin: 0 auto 30px; text-decoration: none; }
      a { text-decoration: none; }
      button { width: 280px; background: #4389C9; font-family: "lato", serif; font-size: 21px; font-weight: 700; color: #fff; border-radius: 25px; padding: 8px 0; text-align: center; text-transform: uppercase; }
      button:hover { background: #1ea74a; transition: 0.3s; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .results {
    & { min-height: 660px; background: #fff; }
    
    .selection-form { 
      &  { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail { flex: 0 1 100%; margin-right: 4%;}
      .form-text { flex: 0 1 100%; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid #348CFE; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 36px; font-family: "lato", sans-serif; }
      }
    }

    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: 'lato', sans-serif; font-size: 36px; color: #333333; padding: 32px 0 0; }
    
    .progress-bar { margin: 80px auto 40px; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 552px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); }
    .si_ass_progress .progress-line { width: 2px; height: 55px; content: ' '; background: #4a4a4a; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #333333; bottom: 60px; left: 0; margin-left: -20px; }
    
    .pervalue h4 { position: relative; }
    .pervalue { max-width: 552px; margin: -18px auto; display: flex; clear: both; color: #333333; font-size: 16px; }
    .pervalue h4.nimp:before { content: '0%'; position: absolute; left: 0; }
    .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: 0; }

    .si_pro_header { max-width:552px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color: #333333; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: 71px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: 71px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 21px; color: #121212; margin: 20px auto; max-width: 555px; }
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: #333333; font-weight: 400; font-size: 18px; margin: 10px auto; }
        .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 28px; }
        .score-slider { width: 80%; margin: 0 auto; margin-bottom: 16px; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; }
    .custom-bar .MuiLinearProgress-bar { background-color: #1EA74A; }
    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: $color1; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 300; font-size: 18px; color: #000; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }

  }

  .invite {
    & { min-height: 380px; width: 100%; max-width: 1110px; padding-bottom: 30px; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "lato", sans-serif; font-size: 36px; color: #1f2a44; padding: 67px 0 0; }
    p { text-align: center; margin: 25px auto 35px; font-size: 21px; line-height: 1.4em; }
    .form {
      & { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px auto; width: 45%; }
      .invite-number { position: absolute; left: -50px; top: 63px; font-weight: bold; color: #C2C2C2; }
      .form-detail {
        & { flex: 0 1 46%; display: block; margin-bottom: 5px; font-weight: 700; }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .invite-field { font-family: 'lato', sans-serif !important; background-color: #F4F4F4; padding: 8px 9px; }
        .invite-field::placeholder { font-family: 'lato', sans-serif !important; font-size: 18px !important; color: #9B9B9B; font-weight: 300; }
        .invite-field input:focus::placeholder { color: transparent; }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
      }      
      .delete-btn { 
        & { position: absolute; right: -58px; top: 64px; }
        .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
      }
    }

    .invite-another-person {
      & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
      .invite-another { width: 74px; background: $color2; position: absolute; top: -62px; cursor: pointer; right: -86px; border-radius: 25px; text-align: center; font-size: 18px; font-weight: 700; padding: 9px 0; color: #fff; }
    }

    .cta-btn {
      & { display: block; margin: 30px auto 50px; text-decoration: none; width: 270px; background: $color1; color: #fff; font-family: "lato", serif; border-radius: 25px; font-size: 21px; padding: 8px 0; text-align: center; text-transform: uppercase; font-weight: bold; }
      &:hover { background: #1ea74a; transition: 0.3s; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .thank-you {
    & { min-height: 380px; max-width: 926px; width: 100%; padding-bottom: 30px; background: #fff; }
    .tick { text-align: center; padding: 80px 0 0; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "lato", serif; font-size: 36px; color: #1f2a44; padding: 32px 0 15px; }
    p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; }
    .back-btn { 
      & { text-align: center; margin-top: 50px; }
      a { border-bottom: 2px solid $color1; text-transform: uppercase; font-weight: bold; cursor: pointer; text-decoration: none; color: #202020; }
    }
  }

  .invite-container {
    & { max-width: calc(1100px - 10%); margin: 50px auto; padding: 0 5% 30px; font-family: "lato", sans-serif; font-size: 21px; line-height: 32px; color: #fff; text-align: center; }
    h3 { font-weight: 700; font-size: 36px; }
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 73px 0 30px; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; }
        span { color: #1EA74A; }
        img { height: 61px; }
      }
    }
    .cta-btn {
      & { width: 280px; background: #3F86C7; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 35px; }
      &:hover { background: #1ea74a; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 21px; font-weight: bold; }
    }
  }

  .team-container {
    & { max-width: calc(880px - 10%); border-radius: 10px; margin: 50px auto; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #fff; text-align: center; font-weight: 400; }
    h3 { line-height: 27px; font-size: 36px; }
    .cta-btn {
      & { width: 220px; background: #3F86C7; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: #1ea74a; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 18px; font-weight: bold; }
    }
  }

  .individual-group {
    & { max-width: 400px; width: 90%; margin: 70px auto 0; display: flex; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: #fff; color: #1F2A44; font-family: "lato", sans-serif; font-size: 18px; font-weight: 600; padding: 16px 0; text-align: center; }
    .left { border-top-left-radius: 100px; border-bottom-left-radius: 100px; }
    .right { border-top-right-radius: 100px; border-bottom-right-radius: 100px; }
    .active { background: $color1; color: #fff; font-weight: 700; }
  }

  .toggle-individual-group { display: none; }

  .dashboard {
    & { min-height: 630px; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: 'lato', serif; font-size: 36px; color: #333333; padding: 32px 0 0; }
    
    .progress-bar { margin: 80px auto 40px; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "lato", sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 552px; height: 26px; margin: 0 auto; border-radius: 13px; width: 100%; background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); }
    .si_ass_progress .progress-line { width: 2px; height: 55px; content: ' '; background: #4a4a4a; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #333333; bottom: 60px; left: 0; margin-left: -20px; }
    
    .pervalue h4 { position: relative; }
    .pervalue { max-width: 552px; margin: -16px auto; display: flex; clear: both; color: #333333; font-size: 14px; }
    .pervalue h4.nimp:before { content: '0%'; position: absolute; left: 0; }
    .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: 0; }

    .si_pro_header { max-width:552px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color: #333333; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: 70px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: 70px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 21px; color: #121212; margin: 20px auto; max-width: 555px; }
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: #333333; font-weight: 400; font-size: 18px; margin: 10px auto; }
        .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 28px; }
        .score-slider { width: 80%; margin: 0 auto; margin-bottom: 16px; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; }
    .custom-bar .MuiLinearProgress-bar { background-color: #1EA74A; }

    table { 
      & { font-family: 'lato', sans-serif; border-collapse: collapse; width: 86%; margin: 70px auto 40px; font-size: 18px; font-weight: 300; }
      tr:nth-child(even) { background-color: #F4F4F4; }
      td, th { border: 1px #dddddd;text-align: left;padding: 8px; }
      .center-cell { text-align: center; }
      .medium { font-weight: 500; }
      .hash { width: 13px; }
      .avg { text-align: center; }
      .header { border-style: none;border-bottom-style: solid;border-width: 3px;border-color: $color1; }
      .footer { 
        & { border-style: none;border-top-style: solid;border-width: 3px;border-color: $color1; color: $color1; }
        .church-average { font-size: 18px; color: #373737; }
      }
    }

    hr { width: 96%; color: #979797; }
 
    h2 { font-family: "lato", serif; font-size: 24px; font-weight: 700; color: #4A4A4A; margin-top: 50px; text-align: center; }
    .most-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
      .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
      .most-answered-detail { font-weight: 400; font-size: 18px; color: #4A4A4A; padding: 10px 30px; width: calc(100% - 60px);}
      .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
    }

    .alignment { 
      & { max-width: calc(860px - 10%); margin: 0 auto 30px; background: #1f2a44; margin: 50px auto 0; padding: 10px; border-radius: 3px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 400px; }
      .tab-buttons { 
        & {display: flex; width: 100%; margin: 0 auto; justify-content: center; }
        button { font-family: "lato", sans-serif; color: #fff; font-size: 18px; border-radius: 3px; font-weight: 600; text-transform: uppercase; text-align: center; padding: 15px; background: none; border: 0px; min-width: 110px; cursor: pointer; }
        .active { background: $color1; }
      }
      .tabs-content { flex-grow: 1; }

      .content { 
        & { width: 100%; display: none; }
        .align-details {
          & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
          .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 300; font-size: 16px; color: #000; padding: 10px 15px; width: calc(100% - 30px); }
            .answer { flex: 1 1 200px; margin-left: 10px; text-align: right; font-weight: 700; }
            .true { color: #73B76F; }
            .neutral { color: #FFB545; }
            .false { color: #FF6645; }
            p { flex: 0 1 94%; }
            .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; }
            .more-details { 
              & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #E66767; font-size: 14px; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { max-width: 30%; padding: 5px; margin: 0; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
        
      }
      .active-content { display: block; }
    }

    .mobile_scores {
      & { display: none; width: 90%; margin: 0 auto; }
      .individual {
        h2 { font-size: 18px; font-weight: 700; }
        & { font-size: 21px; font-weight: 700;border-bottom: 2px solid #3E85C7;  }
        .row { 
          & { height: 40px; display: flex; align-items: center; border-top: 2px solid #3E85C7; }
          &:last-of-type { border-bottom: 2px solid #3E85C7; }
          .number { flex: 0 1 30px; font-size: 18px; }
          .name { flex: 1 1 auto; }
          .percent { color: #3E85C7; font-size: 18px; margin-right: 10px; }
          .toggle { padding-top: 6px; }
        }
        .scores { 
          & { font-size: 18px; border-top: 2px solid #3E85C7; }
          .section_rows { 
            & { display: flex; padding: 12px 16px; }
            &:nth-of-type(even) { background-color: #F4F4F4; }
            .section { flex: 1 1 auto; }
            .section_score { flex: 0 1 auto; }
          }
        }
      }
    }

    .urgency {
      & { max-width: calc(880px - 10%); margin: 20px auto; }
      h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: left; }
      .urgency-slider { 
        & { position: relative; background: $color1opacity30; margin: 0 -30px; padding: 115px 9% 80px; border-radius: 3px; }
        h3 { position: absolute; top: 0; color: #152036; font-weight: 300; font-size: 15px; left: 7%; }

        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 13px; color: #000; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 100000; margin-left: -28px; margin-top: -12px; text-align: center; font-size: 18px; font-weight: bold; color: #fff; }
        .top-labels { position: absolute; width: 1px; height: 33px; top: -46px; margin-left: -2px; background: #1F2A44; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #152036; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background: $color1; position: absolute; z-index: 9; }
        }
      }

      .custom-slider.MuiSlider-root { z-index: 1000; color: $color1opacity42; width: calc(100% + 9px); margin-left: -6px; }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid $color1opacity60; height: 0; }

      .custom-slider .MuiSlider-thumb { background: $color1; }
      .custom-slider .MuiSlider-thumb:before { background: $color1; border: 4px solid $color1; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #152036; height: 2px; }
      .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
    }

    .hurdles-enablers {
      & { max-width: calc(880px - 10%); margin: 20px auto; }
      h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: left; }
      .hurdles { background: #fbece9;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: #313131; text-align: left; border-radius: 3px; }
      .enablers { background: #f1f8f1;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: #313131; text-align: left; border-radius: 3px; }
      li { padding-bottom: 16px; font-weight: 400; font-size: 14px; }
    }

  }

  // .testimonal-container {
  //   & { max-width: calc(880px - 4%); margin: 50px auto; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
  //   .avatar { margin-right: 53px; }
  //   .testimonial { 
  //     & { position: relative; color: #fff; font-family: 'lato', sans-serif; font-size: 21px; line-height: 1.35em; }
  //     .quotation-mark { position: absolute; margin-top: -50px; }
  //     .designation { 
  //       & { color: $color2; font-weight: 700; padding-top: 20px; }
  //       span {  font-weight: 400; }
  //     }
  //   }
  // }
  .testimonal-container {
    & { max-width: 880px; margin: 50px auto 0; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
    .testimonial { 
      & { width: 100%; position: relative; color: #fff; font-family: 'lato', sans-serif; font-size: 24px; line-height: 45px; text-align: center; }
      .avatar {
        img { border-radius: 50%; width: 120px; margin-top: 50px; margin-bottom: -20px; }
      }
      .quotation-mark { 
        & { display: inline-block; font-size: 84px; color: #1EA74A; margin-right: 15px; position: relative; }
        .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .bottom { transform: scale(-1, -1); margin-left: 15px; }
      .designation { 
        & { color: #429653; font-weight: 700; padding-top: 32px; line-height: 1.1em; font-size: 32px; }
        span { font-weight: 600; font-size: 18px; color: #fff; }
      }
    }
  }

  .schedule-container {
    & { max-width: calc(880px - 10%); background: $color1; margin: 20px auto; padding: 75px 5% 30px; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #fff; text-align: center; border-radius: 10px; }
    .cta-btn {
      & { width: 220px; background: $color2; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: #3B455C; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 18px; border-radius: 3px; font-weight: bold; }
    }
  }

  .invite-legacy-container {
    & { max-width: calc(880px - 10%); background: #4389C9; margin: 50px auto; padding: 75px 5% 30px; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #fff; text-align: center; border-radius: 10px; }
    .cta-btn {
      & { width: 280px; background: #1EA74A; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: #3B455C; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 21px; border-radius: 3px; font-weight: bold; }
    }
  }

}

.btn {
  & {
    
  }
}

footer {
  // min-height: 100px;
  padding: 1rem 1rem 2rem 1rem;

  .footer-logos-div {
    display: flex;
    justify-content: space-between;
    max-width: 1110px;
    margin: auto;
  }
}

@media only screen and (min-width: 650px) {

  main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}



@media only screen and (max-width: 1350px) {

  header {
    .logo { margin-left: -50px; }
  }  

}

@media only screen and (max-width: 1250px) {

  header {
    .logo { margin-left: 0; }
  }
  
  .custom-slider.MuiSlider-root { padding: 12px 0; }  

}

@media only screen and (max-width: 1110px) {

  main .dashboard .urgency { overflow: hidden; }
  main .testimonal-container .testimonial .testi br { content: ""; }
  main .testimonal-container .testimonial .testi br:after { content: " "; }

}

@media only screen and (max-width: 830px) {

  header {
    & { flex-direction: column; height: auto; align-items: flex-start; }
    .logo { position: relative; margin: 0 auto; left: 0; margin-bottom: 15px; margin-top: 10px; }
  }

  footer { min-height: 0; height: 0; }
  
  main {
    .start {
      & { flex-direction: column; height: auto; width: 95%; }
      .video { width: 100%; height: auto; border-top-left-radius: 0px; border-bottom-left-radius: 0px; }
      .intro { width: 90%; padding: 30px 5%; height: auto; }
    }
  }
  
  main .start .video video { width: 120%; margin: 0 -10%; }
  main .start .video .thumbnail { width: 100%; height: auto; }
  
  main .dashboard table { font-size: 15px; }
  main .dashboard .most-answered { width: 90%; }
  main .dashboard .urgency { width: 90%; }
  main .dashboard .hurdles-enablers { width: 90%; }
  

}

@media only screen and (min-width: 650px) {

  main .dashboard .urgency .urgency-slider .slider-labels .label p br { content: ""; }
  main .dashboard .urgency .urgency-slider .slider-labels .label p br:after { content: " "; }

}

@media only screen and (max-width: 650px) {

  header .assessment-title { font-size: 30px; margin: -10px 0 2px 17px; }
  main .assessment .steps { width: 90%; padding: 0 5%; }
  main .start .intro h1 { text-align: center; }
  main .start .intro h2 { text-align: center; font-size: 16px; line-height: 25px; }
  main .start .intro h1 br { content: ""; }
  main .start .intro h1 br:after { content: " "; }

}

@media only screen and (max-width: 550px) {

  .desktop { display: none; }
  .mobile { display: block; }

  header .logo img { display: none; }
  header .build-by { top: 98px; left: 50%; margin-left: -55px; }
  header .build-by img { width: 110px; }

  main .start { margin-top: 58px; }
  main .start .video { height: 75vw; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
  main .start .intro h1 { font-size: 21px; }
  main .start .intro .completion img { margin: 0 4px 0 0;}
  
  main .assessment { margin-top: 58px; }
  main .assessment .steps .question { min-height: 180px; font-size: 21px; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 12px; }
  main .assessment .steps .slider-container .slider-labels .label { width: 14%; }
  main .assessment .steps .progress-indicator { margin: 50px auto 30px; }
  main .assessment .steps .progress-bar { flex-wrap: wrap; }
  main .assessment .steps .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 12px; }
  main .assessment .steps .progress-indicator { font-size: 18px; }
  main .assessment .steps .slider-container .slider-labels { font-size: 12px; }
  main .assessment .steps .slider-container .slider-labels .label p.desktop { display: none; }
  main .assessment .steps .slider-container .slider-labels .label p.mobile { display: block; }
  main .assessment .steps .radio-container .slider-labels { font-size: 12px; }
  main .assessment .steps .radio-container .slider-labels .label p.desktop { display: none; }
  main .assessment .steps .radio-container .slider-labels .label p.mobile { display: block; }
  main .assessment .steps .step-buttons { margin: 120px auto 30px; padding-bottom: 20px; }
  
  main .assessment-additional { margin-top: 58px; }
  main .assessment-additional .steps .textarea-field { width: 100%; margin-left: -10px; }
  main .assessment-additional .steps .form-details-text { height: 160px; }
  main .assessment-additional .steps .question { font-size: 18px; }
  main .assessment-additional .steps .question p br { content: ""; }
  main .assessment-additional .steps .question p br:after { content: " "; }
  main .assessment-additional .steps .slider-container .slider-labels { font-size: 12px; }
  main .assessment-additional .steps .step-buttons { padding-bottom: 30px; }
  // main .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  main .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; }

  main .details { width: 95%; margin-top: 58px; }
  main .details h1 { font-size: 32px; }
  main .details p { padding: 0 5%; font-size: 18px; }
  main .details p br { content: ""; }
  main .details p br:after { content: " "; }
  main .details .form { width: 90%; }
  main .details .form .form-detail { flex: 0 1 100%; max-width: 100%; }
  main .details .form .form-detail .form-details-text { padding-right: 0; max-width: 94%; }

  main .results { width: 95%; margin-top: 58px; }
  main .results .selection-form { width: 70%; flex-wrap: wrap; }
  main .results .selection-form .form-detail { width: 100%; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; font-size: 18px; margin-top: 8px; }
  main .results h1 { font-size: 32px; }
  main .results .result-text { margin: 20px 5%; font-size: 18px; }
  main .results .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 20px; }
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar { width: 90%; }
  main .results .si_pro_header h4 { font-size: 13px; }
  main .results .si_pro_header h4.nimp:before { bottom: 63px; }
  main .results .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .results .si_ass_progress .msi_score { font-size: 26px; }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }

  main .invite-container { width: 85%; font-size: 14px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container h3 { font-size: 24px; line-height: 43px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }

  main .schedule-container { width: 85%; font-size: 18px; }
  // main .testimonal-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonal-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonal-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonal-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonal-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonal-container .testimonial .designation { font-size: 26px; }
  main .testimonal-container .testimonial .designation span { padding-top: 6px; }

  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -77px; }

  main .invite { width: 95%; margin-top: 58px; }
  main .invite h1 { font-size: 32px; width: 95%; }
  main .invite h1 br { content: ""; }
  main .invite h1 br:after { content: " "; }
  main .invite p { margin: 25px 3% 35px; font-size: 18px; }
  main .invite p br { content: ""; }
  main .invite p br:after { content: " "; }
  main .invite .form { width: 65%; }
  main .invite .form .form-detail { flex: 0 1 100%; }
  main .invite .form .form-detail .invite-field { max-width: 95%; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -25px; top: 25px; }
  main .invite .form .delete-btn { right: auto; left: -32px; top: 64px; }

  main .invite .invite-another-person .invite-another { top: 25px; right: -26px; left: 0; margin-left: -32px; }
  main .invite .cta-btn { margin-top: 20px; margin-right: 15%; width: 164px; padding: 4px 0; }

  main .thank-you { width: 95%; margin-top: 58px; }
  main .thank-you h1 { font-size: 32px; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 18px; }
  main .testimonal-container .testimonial { font-size: 16px; width: 90%; margin: 20px auto 0; text-align: center; }

  
  main .MuiSwitch-thumb { background-color: #3E85C7 !important; }
  main .MuiSwitch-track { background-color: #E7E9EB !important; opacity: 1; }
  main .individual-group { display: none; }
  main .toggle-individual-group { display: block; margin: 60px auto -45px; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
  main .toggle-individual-group .left { flex: 0 1 25%; text-align: right; }
  main .toggle-individual-group .right { flex: 0 1 35%; }
  main .toggle-individual-group .active { font-weight: 700; color: #3E85C7; }

  main .dashboard { width: 95%; margin-top: 58px; }
  main .dashboard h1 { font-size: 32px; }
  main .dashboard .mobile_scores { display: block; }
  main .dashboard .result-text { margin: 20px 5%; font-size: 18px; }
  main .dashboard .result-sections { margin: 50px 5%; padding-bottom: 0; }
  main .dashboard .result-sections .score-section { flex: 0 1 50%; }
  main .dashboard .result-sections .score-section .score-txt { margin-bottom: 10px; }
  main .dashboard .progress-bar { width: 95%; }
  main .dashboard .si_pro_header h4 { font-size: 13px; }
  main .dashboard .si_pro_header h4.nimp:before { bottom: 63px; }
  main .dashboard .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .dashboard .si_ass_progress .msi_score { font-size: 26px; }
  main .dashboard hr { display: none; }
  main .dashboard table { font-size: 13px; display: none; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency h2 { text-align: center; }
  main .dashboard .urgency .urgency-slider h3 { text-align: center; left: 0; width: 100%; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 12px; }
  main .dashboard .most-answered { font-size: 16px; }
  main .dashboard .most-answered .most-answered-detail { font-size: 14px; }
  main .dashboard .alignment { width: 90%; }
  main .dashboard .alignment .tab-buttons { flex-wrap: wrap; }
  main .dashboard .alignment .tab-buttons button { font-size: 18px; min-width: 55px; padding: 10px 5px; flex: 0 1 50%; }
  main .dashboard .alignment .content .align-details { font-size: 16px; }
  main .dashboard .alignment .content .align-details .answered-detail p { flex: 0 1 90%; }
  main .dashboard .alignment .content .align-details .answered-detail .more-toggle { flex: 0 1 8%; }
  main .dashboard .urgency .urgency-slider { margin: 0; background: transparent; }
  main .dashboard .urgency .custom-slider.MuiSlider-root { color: transparent; }
  main .dashboard .urgency .custom-slider .MuiSlider-track { border: 0px; }
  main .dashboard .urgency .MuiLinearProgress-root.custom-bar { background-color: #AFD9FF; height: 8px; margin-top: -22px; }
  main .dashboard .urgency .slider-points .point { width: 8px; height: 8px; margin-top: -2px; margin-left: -5px; }

  main .results .selection-form .form-details-text .MuiInput-root { font-size: 24px; }
  main .dashboard .alignment .content .align-details.analyze .answered-detail { flex-direction: row-reverse; }

}

@media only screen and (max-width: 550px) {

  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }  
  main .start .intro .completion { flex-wrap: wrap; margin: 48px auto 10px; width: 100%; justify-content: center;  }
  main .start .intro .completion .cta { width: 90%; margin: auto 15% 30px; }

}

@media only screen and (max-width: 450px) {

  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }

}

// Tabs CSS


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: $color1;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

